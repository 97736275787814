import React, { ChangeEvent } from "react";
import {
  Heading,
  Flex,
  FormControl,
  Stack,
  Box,
  Radio,
} from "@chakra-ui/react";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { supplierFormData } from "./AddSupplier";
import { secondaryColors } from "../../../utils/designSystem";

type BusinessTermsProps = {
  formData: supplierFormData;
  setFormData: React.Dispatch<React.SetStateAction<supplierFormData>>;
};
const InvoiceTerms: React.FC<BusinessTermsProps> = ({
  formData,
  setFormData,
}) => {
  const handleInvoiceTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: supplierFormData) => ({
      ...prevFormData,
      businessTerms: {
        ...prevFormData.businessTerms,
        invoiceTerms: {
          ...prevFormData.businessTerms?.invoiceTerms || {},
          [name]: value === "true",
        },
      },
    }));
  };

  return (
    <Stack mt="8">
      <Heading mb="1" as="h5" size="md">
        Invoice Terms   
      </Heading>
      <Box
        borderWidth={1}
        borderRadius={"md"}
        p="4"
        style={{
          marginLeft: "1.5rem",
        }}
        bg={`${secondaryColors.secondary_100}`}
      >
        <Flex alignItems="center" gap={4} mb={4} width={{ base: "70%", md: "26%" }}>
          <FormControl pt="1.5">
            <GaraazFormLabel text="Tax Included in Item Rates" isRequired={false} />
          </FormControl>
          <Flex>
            <FormControl display="flex" alignItems="center">
              <Radio
                name="isTaxInclusive"
                value="true"
                onChange={handleInvoiceTermsChange}
                isChecked={formData.businessTerms?.invoiceTerms.isTaxInclusive}
              >
                Yes
              </Radio>
            </FormControl>
            <FormControl display="flex" alignItems="center" ml={4}>
              <Radio
                name="isTaxInclusive"
                value="false"
                onChange={handleInvoiceTermsChange}
                isChecked={!formData.businessTerms?.invoiceTerms.isTaxInclusive}
              >
                No
              </Radio>
            </FormControl>
          </Flex>
        </Flex>
      </Box>
    </Stack>
  );
};

export default InvoiceTerms;
